import { useContext, useRef, useCallback, useState, useEffect } from 'react';
import { Panel } from '../../components/panel/panel';
import {
  uploadBytes,
  ref,
  listAll,
  getMetadata,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { storage, db } from '../../firebase-config';
import { EmployeesContext } from '../../context';
import List from '../../components/list/list';
import Modal from '../../components/modal/modal';
import Button from '../../components/button/button';
import './employee-documents.css';
import Icon from '../../components/icon/icon';
import FileUpload from '../../components/file-upload/file-upload';
import Skeleton from 'react-loading-skeleton';
import ImportantList from '../../components/important-list/important-list';
import { convertToLocalTime, convertToUTC } from '../../utils/formatDates';

const uploadFiles = async (
  files,
  companyId,
  employeeId,
  onUploadComplete,
  handleDeleteClick,
  employeeContract = false
) => {
  // Check for early return conditions
  if (!files || !companyId || !employeeId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  const fileDataArray = await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/users/${employeeId}/documents/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      // If the file is an employee contract, we should also update the employee's contract status on the user
      if (employeeContract) {
        // Check if we have employee data and a document ID
        const employeeDocRef = doc(db, 'users', employeeId);

        try {
          // Update the document in Firestore
          await setDoc(
            employeeDocRef,
            {
              employmentContract: {
                url: fileUrl,
                name: file.name,
                uploaded: Timestamp.fromDate(convertToUTC(new Date())),
              },
            },
            { merge: true }
          );
        } catch (error) {
          console.error(error);
        }
      }

      return {
        id: filePath,
        fields: [
          {
            label: 'Titel',
            value: file.name,
            sortBy: file.name,
            searchBy: file.name,
          },
          {
            label: 'Uploaded',
            value: convertToLocalTime(new Date(), {
              dateStyle: 'short',
              timeStyle: 'short',
            }),
          },
          {
            label: 'Filtype',
            value: file.name.split('.').pop() || 'Ikke angivet',
          },
          {
            label: 'Handlinger',
            value: (
              <div className="employee-documents-actions">
                <a
                  href={fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="employee-documents-actions__view-link"
                >
                  <Icon name="open_in_new" />
                  <span>Vis</span>
                </a>
                <div
                  className="employee-documents-actions__delete-link"
                  onClick={() => handleDeleteClick(filePath)}
                >
                  <Icon name="close" />
                  <span>Slet</span>
                </div>
              </div>
            ),
          },
        ],
        filterData: {
          title: file.name,
          uploaded: convertToLocalTime(new Date(), {
            dateStyle: 'short',
            timeStyle: 'short',
          }),
          fileType: file.name.split('.').pop() || 'Ikke angivet',
        },
      };
    })
  );

  if (onUploadComplete && typeof onUploadComplete === 'function') {
    onUploadComplete(fileDataArray);
  }
  return true;
};

export default function EmployeeDocuments({
  employeeData,
  userInfo,
  employeeId,
}) {
  const isInitialLoad = useRef(true);
  const [documents, setDocuments] = useState([]);
  const employees = useContext(EmployeesContext);
  const employee = employees.find((emp) => emp.id === employeeId);
  const employmentContract = employee?.data?.employmentContract;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFileForDeletion, setSelectedFileForDeletion] = useState(null);

  const handleDeleteClick = (filePath) => {
    setSelectedFileForDeletion(filePath);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedFileForDeletion) {
      await handleDelete(selectedFileForDeletion);
      setIsDeleteModalOpen(false);
      setSelectedFileForDeletion(null); // Reset the selected file after deletion
    }
  };

  // Handler to delete a document
  const handleDelete = async (path) => {
    try {
      const fileRef = ref(storage, path);

      // Check if the file that is being deleted is the employee contract, if so, we should also update the employee's contract status on the user to null
      if (
        employmentContract &&
        employmentContract.name === path.split('/').pop()
      ) {
        const employeeDocRef = doc(db, 'users', employeeId);

        try {
          // Update the document in Firestore
          await setDoc(
            employeeDocRef,
            {
              employmentContract: null,
            },
            { merge: true }
          );
        } catch (error) {
          console.error(error);
        }
      }

      await deleteObject(fileRef);

      setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== path));
    } catch (error) {
      console.error(error);
    }
  };

  // Handler to update documents state with new files
  const handleFilesUploaded = useCallback((newFiles) => {
    setDocuments((prevDocs) => [...prevDocs, ...newFiles]);
  }, []);

  // Fetch documents for the employee
  useEffect(() => {
    // Immediately set isInitialLoad to false to ensure Skeleton UI can be removed
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }

    const fetchDocuments = async () => {
      const listRef = ref(
        storage,
        `${userInfo.companyId}/users/${employeeId}/documents`
      );

      try {
        const res = await listAll(listRef);
        const documentsPromises = res.items.map(async (itemRef) => {
          try {
            const [metadata, downloadURL] = await Promise.all([
              getMetadata(itemRef),
              getDownloadURL(itemRef),
            ]);
            const timeCreatedDate = new Date(
              metadata.timeCreated
            ).toLocaleString('da-DK', {
              dateStyle: 'short',
              timeStyle: 'short',
            });

            return {
              id: itemRef.fullPath,
              fields: [
                {
                  label: 'Titel',
                  flex: 25,
                  value: (
                    <div
                      style={{
                        // Ellipsis overflow
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        maxWidth: '80%',
                      }}
                    >
                      {itemRef.name}
                    </div>
                  ),
                  sortBy: itemRef.name,
                  searchBy: itemRef.name,
                },
                {
                  label: 'Oprettet',
                  flex: 15,
                  value: timeCreatedDate,
                },
                {
                  label: 'Filtype',
                  flex: 8,
                  value: itemRef.name.split('.').pop() || 'Ikke angivet',
                },
                {
                  label: 'Handlinger',
                  flex: 15,
                  value: (
                    <div className="employee-documents-actions">
                      <a
                        href={downloadURL}
                        target="_blank"
                        rel="noreferrer"
                        className="employee-documents-actions__view-link"
                      >
                        <Icon name="open_in_new" />
                        <span>Vis</span>
                      </a>
                      <div
                        className="employee-documents-actions__delete-link"
                        onClick={() => handleDeleteClick(itemRef.fullPath)}
                      >
                        <Icon name="close" />
                        <span>Slet</span>
                      </div>
                    </div>
                  ),
                },
              ],
              filterData: {
                title: itemRef.name,
                oprettet: timeCreatedDate,
                fileType: itemRef.name.split('.').pop() || 'Ikke angivet',
              },
            };
          } catch (error) {
            console.error(error);
            return null;
          }
        });

        const documentsResolved = (await Promise.all(documentsPromises)).filter(
          (doc) => doc !== null
        );

        setDocuments(documentsResolved);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDocuments();
  }, [employeeId, userInfo.companyId]);

  return (
    <>
      {isInitialLoad.current ? (
        <Panel>
          <Skeleton height={164} count={1} />
          <Skeleton height={50} count={2} />
        </Panel>
      ) : (
        <>
          <ImportantList
            header={<h4>Vigtige dokumenter</h4>}
            content={[
              employeeData?.settings?.documents?.exemptFromEmploymentContract
                ? null
                : {
                    title: 'Ansættelseskontrakt',
                    text: employmentContract
                      ? `Oprettet d. ${convertToLocalTime(
                          employmentContract.uploaded,
                          {
                            dateStyle: 'short',
                          }
                        )}`
                      : 'Mangler',
                    missing: employmentContract ? false : true,
                    actions: employmentContract ? (
                      <>
                        <a
                          href={employmentContract.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon name="open_in_new" />
                          <span>Vis</span>
                        </a>
                        <div
                          onClick={() =>
                            handleDeleteClick(
                              `${userInfo.companyId}/users/${employeeId}/documents/${employmentContract.name}`
                            )
                          }
                        >
                          <Icon name="close" />
                          <span>Slet</span>
                        </div>
                      </>
                    ) : (
                      <FileUpload
                        singleFile
                        label="Upload"
                        dragAndDrop={false}
                        handleUpload={(files) =>
                          uploadFiles(
                            files,
                            userInfo.companyId,
                            employeeId,
                            handleFilesUploaded,
                            handleDeleteClick,
                            true
                          )
                        }
                      />
                    ),
                  },
            ]}
          />
          <Panel noPadding>
            <div className="employee-documents__upload">
              <FileUpload
                handleUpload={(files) =>
                  uploadFiles(
                    files,
                    userInfo.companyId,
                    employeeId,
                    handleFilesUploaded,
                    handleDeleteClick
                  )
                }
              />
            </div>
            <List
              head={[
                { label: 'Titel', flex: 25, sortable: true },
                { label: 'Oprettet', flex: 15, sortable: true },
                { label: 'Filtype', flex: 8, sortable: true },
                { label: 'Handlinger', flex: 15, sortable: false },
              ]}
              items={documents}
              noResultsMessage="Ingen dokumenter oprettet"
            />
          </Panel>
          {isDeleteModalOpen && (
            <Modal
              isModalOpen={isDeleteModalOpen}
              setIsModalOpen={setIsDeleteModalOpen}
              header="Slet fil"
              content={
                <div className="delete-modal-content">
                  <Icon name="warning" />
                  <p>
                    Er du sikker på at du vil slette filen{' '}
                    <strong>{selectedFileForDeletion.split('/').pop()}</strong>?{' '}
                  </p>
                </div>
              }
              bottom={
                <>
                  <Button buttonStyle="danger" onClick={confirmDelete}>
                    Slet fil
                  </Button>
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
}
