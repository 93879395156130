import { useContext, useState } from 'react';

import { EmployeesContext } from '../../../context';

import './agreements-list.css';
import Tabs from '../../tabs/tabs';
import List from '../../list/list';
import { Panel } from '../../panel/panel';
import AGREEMENT_TYPE from '../../../constants/fields/agreementType';
import AgreementsCreateModal from '../../../views/agreements/agreements-create-modal';
import Button from '../../../components/button/button';

export default function AgreementsList({
  agreements,
  showNewAgreementButton = false,
  showRegardingFilter = true,
  showTypeFilter = true,
  showTabs = true,
  showStatus = true,
  isCreateModalOpen,
  setIsCreateModalOpen,
  selectedAgreementType,
  selectedRegardingId,
}) {
  const employees = useContext(EmployeesContext);
  const [activeTab, setActiveTab] = useState('Åben'); // Default to "Åben" tab

  const filteredAgreements = agreements.filter(
    (agreement) => agreement.filterData.Status === activeTab
  );
  return (
    <>
      {showTabs && (
        <Tabs
          tabs={[
            {
              label: 'Åben',
              count: agreements.filter(
                (agreement) => agreement.filterData.Status === 'Åben'
              ).length,
            },
            {
              label: 'Afsluttet',
              count:
                agreements.filter(
                  (agreement) => agreement.filterData.Status === 'Afsluttet'
                ).length || 0,
            },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      <Panel noPadding>
        <List
          head={[
            { flex: 30, label: 'Titel' },
            showStatus && { flex: 10, label: 'Status' },
            { flex: 15, label: 'Dato' },
          ]}
          actions={
            showNewAgreementButton && (
              <Button
                className="small"
                buttonStyle="secondary"
                onClick={() => setIsCreateModalOpen(true)}
              >
                Ny aftale
              </Button>
            )
          }
          filters={[
            showRegardingFilter && {
              label: 'Vedrørende',
              options: employees.map((employee) => ({
                value: employee.id,
                label: `${employee.data.firstName} ${employee.data.lastName}`,
              })),
            },
            showTypeFilter && {
              label: 'Type',
              options: AGREEMENT_TYPE,
            },
          ].filter(Boolean)}
          items={filteredAgreements}
          noResultsMessage="Ingen aftaler oprettet"
        />
      </Panel>
      <AgreementsCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        selectedAgreementType={selectedAgreementType}
        selectedRegardingId={selectedRegardingId}
      />
    </>
  );
}
