import { useContext, useState, useEffect, useRef } from 'react';
import EmptyStateImage from './emptystate.webp';
import './agreements-view.css';
import { collection, getDocs, where, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { UserInfoContext, EmployeesContext } from '../../context';
import { Panels, Panel } from '../../components/panel/panel';
import Avatar from '../../components/avatar/avatar';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import AgreementsCreateModal from './agreements-create-modal';
import EmptyState from '../../components/empty-state/empty-state';
import Skeleton from 'react-loading-skeleton';
import { convertToLocalTime } from '../../utils/formatDates';
import 'react-loading-skeleton/dist/skeleton.css';
import AgreementsList from '../../components/agreements/agreements-list/agreements-list';

export default function AgreementsView() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [agreements, setAgreements] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  // Fetch agreements from database and display them in a list
  useEffect(() => {
    const fetchAgreements = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const agreementsRef = collection(db, 'agreements');
        const q = query(
          agreementsRef,
          where('companyId', '==', userInfo.companyId),
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);
        const agreementsData = [];

        const fetchPromises = querySnapshot.docs.map(async (agreement) => {
          const regarding = employees.find(
            (employee) => employee.id === agreement.data().regarding.id
          );

          // Prepare agreement data for the state
          return {
            link: `/aftaler/${agreement.id}`,
            id: agreement.id,
            fields: [
              {
                label: 'Vedrørende',
                flex: 30,
                value: (
                  <div className="agreements-list-row-profile">
                    <Avatar
                      firstName={regarding?.data.firstName}
                      lastName={regarding?.data.lastName}
                      profilePhotoUrl={regarding?.data.profilePhotoUrl}
                    />
                    <div className="agreements-list-row-title-wrapper">
                      <span className="agreements-list-row-title">
                        {agreement.data().title}
                      </span>
                      <span className="agreements-list-row-regarding">
                        {`${regarding?.data.firstName} ${regarding?.data.lastName}`}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                label: 'Status',
                flex: 10,
                value: agreement.data().isClosed ? 'Afsluttet' : 'Åben',
              },
              {
                label: 'Dato',
                flex: 20,
                value: (
                  <div className="agreements-list-row-date">
                    {agreement.data().date &&
                      convertToLocalTime(agreement.data().date.toDate(), {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                    {!agreement.data().isClosed &&
                      agreement.data().date &&
                      new Date(agreement.data().date.toDate()) < new Date() && (
                        <span className="agreements-list-row-date-expired">
                          <Icon name="warning" /> Forfalden
                        </span>
                      )}
                  </div>
                ),
              },
            ],
            filterData: {
              Vedrørende: regarding?.id,
              Type: agreement.data().agreementType,
              Status: agreement.data().isClosed ? 'Afsluttet' : 'Åben',
            },
          };
        });

        const resolvedAgreements = await Promise.all(fetchPromises);
        agreementsData.push(...resolvedAgreements);
        setAgreements(agreementsData);
        setDisplayEmptyState(agreementsData.length === 0);

        isInitialLoad.current = false;
      }
    };

    fetchAgreements();
  }, [userInfo, employees]);

  return (
    <div className="main-layout">
      <PageTop
        title="Møder og aftaler"
        description="Få hurtigt overblik over alle møder og aftaler med medarbejderne."
        actions={
          <Button onClick={() => setIsCreateModalOpen(true)}>
            <Icon className="light" name="add_circle" />
            Ny aftale
          </Button>
        }
      />
      {isInitialLoad.current ? (
        <>
          <Skeleton
            height={37}
            style={{
              marginBottom: '32px',
            }}
            count={1}
          />
          <Panels>
            <Panel>
              <Skeleton height={50} count={2} />
            </Panel>
          </Panels>
        </>
      ) : displayEmptyState ? (
        <EmptyState
          title="Få hurtigt overblik over alle kollegaerne og tilføj nemt og sikkert nye."
          description="Med funktionen Møder og Aftaler får du overblik over alle dine møder med medarbejderne, du får påmindelser så du ikke glemmer noget og det giver dig mulighed for at have et samlet note- og aftaleoverblik, så du nemt kan finde det hele igen."
          image={EmptyStateImage}
        >
          <Button onClick={() => setIsCreateModalOpen(true)}>
            <Icon className="light" name="add_circle" />
            Opret din første aftale
          </Button>
        </EmptyState>
      ) : (
        <AgreementsList agreements={agreements} />
      )}

      <AgreementsCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
      />
    </div>
  );
}
