import { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import EmptyStateImage from './emptystate.webp';
import { Panels, Panel } from '../../components/panel/panel';
import { UserInfoContext, EmployeesContext } from '../../context';
import { db } from '../../firebase-config';
import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import PageTop from '../../components/page-top/page-top';
import EmptyState from '../../components/empty-state/empty-state';
import Tabs from '../../components/tabs/tabs';
import List from '../../components/list/list';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import InputField from '../../components/input-field/input-field';
import ProgressIndicator from '../../components/progress-indicator/progress-indicator';
import Avatar from '../../components/avatar/avatar';
import Skeleton from 'react-loading-skeleton';
import './onboarding-offboarding-view.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { convertToLocalTime, convertToUTC } from '../../utils/formatDates';

export default function OnboardingOffboardingView() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [activeTab, setActiveTab] = useState('onboarding'); // Default to "onboarding" tab
  const [showCompleted, setShowCompleted] = useState(false);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
    useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    template: '',
    regarding: null,
  });
  const [templateFormData, setTemplateFormData] = useState({
    title: '',
    type: 'onboarding',
    duplicateFrom: '',
  });

  // Handle create form change
  const handleFormChange = (e) => {
    if (e === null) {
      setFormData((prev) => ({
        ...prev,
        regarding: null,
      }));
      return;
    }

    const { name, value } = e.target;

    if (name === 'template') {
      if (value !== 'blank' && value !== '') {
        const templateToUse = templateList.find(
          (template) => template.id === value
        );

        setFormData((prev) => ({
          ...prev,
          title: templateToUse.fields[0].value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          title: '',
        }));
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreate = async () => {
    // Create a new onboarding/offboarding
    const newData = {
      companyId: userInfo.companyId,
      completed: false,
      createdBy: doc(db, 'users', userInfo.id),
      date: Timestamp.fromDate(convertToUTC(new Date())),
      groups: [],
      regarding: formData.regarding
        ? doc(db, 'users', formData.regarding)
        : null,
      title: formData.title,
      type: activeTab,
    };

    if (formData.template && formData.template !== 'blank') {
      const templateToUse = templateList.find(
        (template) => template.id === formData.template
      );

      newData.groups = templateToUse.data.groups;

      // Add new id to each group, task and subtask with crypto.randomUUID
      newData.groups.forEach((group) => {
        group.id = crypto.randomUUID();
        if (group.tasks) {
          group.tasks.forEach((task) => {
            task.id = crypto.randomUUID();

            if (task.subtasks) {
              task.subtasks.forEach((subtask) => {
                subtask.id = crypto.randomUUID();
              });
            }
          });
        }
      });
    }

    // Add the new onboarding/offboarding to the database
    const docRef = await addDoc(
      collection(db, 'onboardingOffboarding'),
      newData
    );

    // Close the modal
    setIsCreateModalOpen(false);

    // Reset the form
    setFormData({
      title: '',
      type: '',
      template: '',
      regarding: null,
    });

    // Navigate to the new onboarding/offboarding template
    navigate(`/onboarding-offboarding/${docRef.id}`);
  };

  // Handle template form change
  const handleTemplateFormChange = (e) => {
    const { name, value } = e.target;

    setTemplateFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateTemplate = async () => {
    // Create a new onboarding/offboarding template
    const newTemplate = {
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      date: Timestamp.fromDate(convertToUTC(new Date())),
      groups: [],
      regarding: null,
      title: templateFormData.title,
      type: templateFormData.type,
    };

    if (
      templateFormData.duplicateFrom &&
      templateFormData.duplicateFrom !== 'blank'
    ) {
      const templateToDuplicate = templateList.find(
        (template) => template.id === templateFormData.duplicateFrom
      );

      newTemplate.groups = templateToDuplicate.data.groups;

      // Add new id to each group, task and subtask with crypto.randomUUID
      newTemplate.groups.forEach((group) => {
        group.id = crypto.randomUUID();
        if (group.tasks) {
          group.tasks.forEach((task) => {
            task.id = crypto.randomUUID();

            if (task.subtasks) {
              task.subtasks.forEach((subtask) => {
                subtask.id = crypto.randomUUID();
              });
            }
          });
        }
      });
    }

    // Add the new onboarding/offboarding template to the database
    const docRef = await addDoc(
      collection(db, 'onboardingOffboardingTemplates'),
      newTemplate
    );

    // Close the modal
    setIsCreateTemplateModalOpen(false);

    // Reset the form
    setTemplateFormData({
      title: '',
      type: 'onboarding',
      duplicateFrom: '',
    });

    // Navigate to the new onboarding/offboarding template
    navigate(`/onboarding-offboarding/${docRef.id}/template`);
  };

  // Fetch onboarding/offboarding from database and display them in a list
  useEffect(() => {
    const fetchList = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const listRef = collection(db, 'onboardingOffboarding');
        const q = query(listRef, where('companyId', '==', userInfo.companyId));
        const querySnapshot = await getDocs(q);
        const listData = [];

        const fetchPromises = querySnapshot.docs.map(async (item) => {
          const regarding = employees.find(
            (employee) => employee.id === item.data()?.regarding?.id
          );

          const totalTasksCount = item
            .data()
            .groups.reduce((acc, group) => acc + group.tasks.length, 0);
          const currentCompletedTasksCount = item
            .data()
            .groups.reduce(
              (acc, group) =>
                acc + group.tasks.filter((task) => task.checked).length,
              0
            );

          // Prepare onboarding/offboarding data for the state
          return {
            link: `/onboarding-offboarding/${item.id}`,
            id: item.id,
            fields: [
              {
                label: 'Vedrørende',
                flex: 25,
                value: (
                  <div
                    className={`onboarding-offboarding-list-row-profile
                  ${
                    item.data().completed
                      ? 'onboarding-offboarding-list-row-profile--completed'
                      : ''
                  }
                  `}
                  >
                    {regarding ? (
                      <>
                        <Avatar
                          firstName={regarding?.data.firstName}
                          lastName={regarding?.data.lastName}
                          profilePhotoUrl={regarding?.data.profilePhotoUrl}
                        />
                        <div className="onboarding-offboarding-list-row-title-wrapper">
                          <span className="onboarding-offboarding-list-row-title">
                            {item.data().title}
                          </span>
                          <span className="onboarding-offboarding-list-row-regarding">
                            {`${regarding?.data.firstName} ${regarding?.data.lastName}`}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="onboarding-offboarding-list-row-title-wrapper onboarding-offboarding-list-row-title-wrapper--no-avatar">
                        <span className="onboarding-offboarding-list-row-title">
                          {item.data().title}
                        </span>
                        <span className="onboarding-offboarding-list-row-regarding">
                          Ikke tildelt
                        </span>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                label: 'Opdateret d.',
                flex: 20,
                value:
                  convertToLocalTime(item?.data()?.updatedAt, {
                    dateStyle: 'short',
                  }) ||
                  convertToLocalTime(item?.data()?.date, {
                    dateStyle: 'short',
                  }) ||
                  'N/A',
              },
              {
                label: 'Status',
                flex: 20,
                value: (
                  <ProgressIndicator
                    current={currentCompletedTasksCount}
                    total={totalTasksCount}
                    completed={item.data().completed}
                    label={'Udførte opgaver'}
                  />
                ),
              },
            ],
            filterData: {
              Vedrørende: regarding?.id,
              Type: item.data().type,
              'Vis gennemførte': item.data().completed,
            },
          };
        });

        const resolvedList = await Promise.all(fetchPromises);
        listData.push(...resolvedList);
        setList(listData);
        setDisplayEmptyState(listData.length === 0);

        isInitialLoad.current = false;
      }
    };

    fetchList();
  }, [userInfo, employees]);

  // Fetch onboarding/offboarding templates from database and display them in a list
  useEffect(() => {
    const fetchList = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const listRef = collection(db, 'onboardingOffboardingTemplates');
        const q = query(listRef, where('companyId', '==', userInfo.companyId));
        const querySnapshot = await getDocs(q);
        const listData = [];

        const fetchPromises = querySnapshot.docs.map(async (item) => {
          // Prepare onboarding/offboarding data for the state
          return {
            link: `/onboarding-offboarding/${item.id}/template`,
            id: item.id,
            fields: [
              {
                label: 'Titel',
                flex: 25,
                value: item.data().title,
              },
              {
                label: 'Opdateret d.',
                flex: 15,
                value:
                  convertToLocalTime(item?.data()?.updatedAt, {
                    dateStyle: 'short',
                  }) ||
                  convertToLocalTime(item?.data()?.date, {
                    dateStyle: 'short',
                  }) ||
                  'N/A',
              },
              {
                label: 'Type',
                flex: 15,
                value:
                  item.data()?.type?.charAt(0)?.toUpperCase() +
                  item.data()?.type?.slice(1),
              },
            ],
            filterData: {
              Type: item.data().type,
            },
            data: item.data(),
          };
        });

        const resolvedList = await Promise.all(fetchPromises);
        listData.push(...resolvedList);
        setTemplateList(listData);

        isInitialLoad.current = false;
      }
    };

    fetchList();
  }, [userInfo, employees]);

  const filteredList = list.filter(
    (item) => item.filterData.Type === activeTab
  );

  return (
    <div className="main-layout">
      <PageTop
        title="On-/Offboarding"
        description="Giv dine nye medarbejdere den bedst mulige start."
      />
      {isInitialLoad.current ? (
        <>
          <Skeleton
            height={37}
            style={{
              marginBottom: '32px',
            }}
            count={1}
          />
          <Panels>
            <Panel>
              <Skeleton height={50} count={2} />
            </Panel>
          </Panels>
        </>
      ) : (
        <>
          <Tabs
            tabs={[
              {
                label: 'onboarding',
                count:
                  list.filter(
                    (item) =>
                      // Filter by 'Vis gennemførte' where its false and count the number of items
                      item.filterData.Type === 'onboarding' &&
                      !item.filterData['Vis gennemførte']
                  ).length || null,
              },
              {
                label: 'offboarding',
                count:
                  list.filter(
                    (item) =>
                      // Filter by 'Vis gennemførte' where its false and count the number of items
                      item.filterData.Type === 'offboarding' &&
                      !item.filterData['Vis gennemførte']
                  ).length || null,
              },
              {
                label: 'skabeloner',
                placement: 'right',
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {activeTab === 'skabeloner' ? (
            <Panels>
              <Panel noPadding>
                <List
                  key={'skabeloner'}
                  noResultsMessage="Ingen Skabeloner fundet. Har du tilføjet nogen?"
                  actions={
                    <Button
                      className="small"
                      onClick={() => setIsCreateTemplateModalOpen(true)}
                    >
                      Tilføj skabelon
                    </Button>
                  }
                  head={[
                    { label: 'Titel', flex: 25 },
                    { label: 'Opdateret d.', flex: 15 },
                    { label: 'Type', flex: 15 },
                  ]}
                  filters={[
                    {
                      label: 'Type',
                      options: [
                        { value: 'onboarding', label: 'Onboarding' },
                        { value: 'offboarding', label: 'Offboarding' },
                      ],
                    },
                  ]}
                  items={templateList}
                />
              </Panel>
            </Panels>
          ) : displayEmptyState ? (
            <EmptyState
              title="Kom nemt og hurtigt i gang med at lave jeres egne on-boarding og off-boarding tjeklister"
              description="Tag udgangspunkt i vores skabelon og tilpas nemt, så den passer perfekt til jeres behov. Funktionerne gør det let for jer at følge op og sikre, at den nye kollega kommer godt i gang. On-/offboarding-modulet hjælper jer med at standardisere og automatisere processer for at spare tid og sikre, at opgaverne bliver udført på det rette tidspunkt og af den rette person."
              image={EmptyStateImage}
            >
              <Button
                buttonStyle="primary"
                onClick={() => setIsCreateModalOpen(true)}
              >
                {activeTab === 'onboarding'
                  ? 'Opret din første onboarding'
                  : 'Opret din første offboarding'}
              </Button>
            </EmptyState>
          ) : (
            <Panels>
              <Panel noPadding>
                <List
                  key={'onboarding-offboarding'}
                  actions={
                    activeTab === 'onboarding' ? (
                      <Button
                        className="small"
                        onClick={() => setIsCreateModalOpen(true)}
                      >
                        Ny onboarding
                      </Button>
                    ) : (
                      <Button
                        className="small"
                        onClick={() => setIsCreateModalOpen(true)}
                      >
                        Ny offboarding
                      </Button>
                    )
                  }
                  head={[
                    { label: 'Vedrørende', flex: 25 },
                    { label: 'Opdateret d.', flex: 20 },
                    { label: 'Status', flex: 20 },
                  ]}
                  filters={[
                    {
                      label: 'Vedrørende',
                      options: employees.map((employee) => ({
                        value: employee.id,
                        label: `${employee.data.firstName} ${employee.data.lastName}`,
                      })),
                    },
                    {
                      label: 'Vis gennemførte',
                      type: 'checkbox',
                      onChange: (checked) => setShowCompleted(checked),
                    },
                  ]}
                  items={filteredList.filter(
                    (item) =>
                      showCompleted || !item.filterData['Vis gennemførte']
                  )}
                />
              </Panel>
            </Panels>
          )}
        </>
      )}

      <Modal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        header={`Ny ${activeTab}`}
        content={
          <>
            <InputField
              label="Vælg skabelon"
              type="select"
              name="template"
              defaultValue={formData.template || 'blank'}
              required
              onChange={handleFormChange}
              placeholder="- Vælg -"
              options={[
                ...templateList.map((template) => ({
                  value: template.id,
                  label: template.fields[0].value,
                })),
              ]}
            />
            <InputField
              label="Titel"
              type="text"
              name="title"
              value={formData.title}
              required
              onChange={handleFormChange}
            />
            <InputField
              label="Vedrørende"
              name="regarding"
              type="select2"
              isClearable
              onChange={handleFormChange}
              defaultValue={formData.regarding}
              options={
                employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                  target: {
                    value: employee.id,
                    name: 'regarding',
                  },
                })) || []
              }
            />
          </>
        }
        bottom={
          <Button buttonStyle="primary" onClick={handleCreate}>
            Opret
          </Button>
        }
      />

      <Modal
        isModalOpen={isCreateTemplateModalOpen}
        setIsModalOpen={setIsCreateTemplateModalOpen}
        header="Ny skabelon"
        content={
          <>
            <InputField
              label="Titel"
              type="text"
              name="title"
              value={templateFormData.title}
              required
              onChange={handleTemplateFormChange}
            />
            <InputField
              label="Type"
              type="select"
              name="type"
              defaultValue={templateFormData.type || 'onboarding'}
              required
              onChange={handleTemplateFormChange}
              options={[
                { value: 'onboarding', label: 'Onboarding' },
                { value: 'offboarding', label: 'Offboarding' },
              ]}
            />
            <InputField
              label="Kopier fra eksisterende skabelon"
              type="select"
              name="duplicateFrom"
              defaultValue={templateFormData.duplicateFrom || 'blank'}
              required
              placeholder="- Vælg -"
              onChange={handleTemplateFormChange}
              options={[
                { value: 'blank', label: 'Blank' },
                ...templateList.map((template) => ({
                  value: template.id,
                  label: template.fields[0].value,
                })),
              ]}
            />
          </>
        }
        bottom={
          <Button buttonStyle="primary" onClick={handleCreateTemplate}>
            Opret
          </Button>
        }
      />
    </div>
  );
}
