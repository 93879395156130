import { useState, useEffect, useRef, useContext } from 'react';
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { UserInfoContext, EmployeesContext } from '../../context';
import Avatar from '../../components/avatar/avatar';
import Icon from '../../components/icon/icon';
import AgreementsList from '../../components/agreements/agreements-list/agreements-list';
import { convertToLocalTime } from '../../utils/formatDates';

export default function DashboardAgreements() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [agreements, setAgreements] = useState([]);

  // Fetch agreements from database and display them in a list
  useEffect(() => {
    const fetchAgreements = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const agreementsRef = collection(db, 'agreements');
        const q = query(
          agreementsRef,
          where('companyId', '==', userInfo.companyId),
          where('isClosed', '==', false),
          orderBy('date', 'asc'),
          limit(5)
        );
        const querySnapshot = await getDocs(q);
        const agreementsData = [];

        const fetchPromises = querySnapshot.docs.map(async (agreement) => {
          const regarding = employees.find(
            (employee) => employee.id === agreement.data().regarding.id
          );

          // Prepare agreement data for the state
          return {
            link: `/aftaler/${agreement.id}`,
            id: agreement.id,
            fields: [
              {
                label: 'Vedrørende',
                flex: 30,
                value: (
                  <div className="agreements-list-row-profile">
                    <Avatar
                      firstName={regarding?.data.firstName}
                      lastName={regarding?.data.lastName}
                      profilePhotoUrl={regarding?.data.profilePhotoUrl}
                    />
                    <div className="agreements-list-row-title-wrapper">
                      <span className="agreements-list-row-title">
                        {agreement.data().title}
                      </span>
                      <span className="agreements-list-row-regarding">
                        {`${regarding?.data.firstName} ${regarding?.data.lastName}`}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                label: 'Dato',
                flex: 15,
                value: (
                  <div className="agreements-list-row-date">
                    {agreement.data().date &&
                      convertToLocalTime(agreement.data().date.toDate(), {
                        dateStyle: 'short',
                      })}
                    {!agreement.data().isClosed &&
                      agreement.data().date &&
                      new Date(agreement.data().date.toDate()) < new Date() && (
                        <span className="agreements-list-row-date-expired">
                          <Icon name="warning" /> Forfalden
                        </span>
                      )}
                  </div>
                ),
              },
            ],
            filterData: {
              Vedrørende: regarding?.id,
              Type: agreement.data().agreementType,
              Status: agreement.data().isClosed ? 'Afsluttet' : 'Åben',
            },
          };
        });

        const resolvedAgreements = await Promise.all(fetchPromises);
        agreementsData.push(...resolvedAgreements);
        setAgreements(agreementsData);

        isInitialLoad.current = false;
      }
    };

    fetchAgreements();
  }, [userInfo, employees]);

  return agreements.length === 0 && !isInitialLoad.current ? (
    <div className="dashboard-panel-empty">
      <span className="dashboard-panel-empty__icon">👀</span>
      Du har ingen kommende aftaler, måske er det tid til at oprette en ny?
    </div>
  ) : (
    <AgreementsList
      showTypeFilter={false}
      showRegardingFilter={false}
      showStatus={false}
      showTabs={false}
      agreements={agreements}
    />
  );
}
