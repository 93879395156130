import { useContext, useState, useEffect, useRef } from 'react';
import EmptyStateImage from './emptystate.webp';
import './equipment-view.css';
import { collection, getDocs, where, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase-config';
import {
  UserInfoContext,
  EmployeesContext,
  EquipmentContext,
  SubscriptionContext,
} from '../../context';
import { Panels, Panel } from '../../components/panel/panel';
import List from '../../components/list/list';
import Avatar from '../../components/avatar/avatar';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Tabs from '../../components/tabs/tabs';
import Icon from '../../components/icon/icon';
import EmptyState from '../../components/empty-state/empty-state';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import EquipmentCreateModal from './equipment-create-modal';
import SubscriptionPlansModal from '../subscription/subscription-plans-modal';
import { convertToLocalTime } from '../../utils/formatDates';

export default function EquipmentView() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const { subscription } = useContext(SubscriptionContext);
  const { equipmentTypes, equipmentVariants } = useContext(EquipmentContext);
  const [equipment, setEquipment] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState(false); // Default to false, as the default tab is "Udstyr"
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [activeTab, setActiveTab] = useState('Udstyr'); // Default to "Udstyr" tab

  const paidSubscription =
    subscription?.name === 'premium' || subscription?.name === 'premiumplus';

  const handleCreateCategoryModalClick = () => {
    setNewCategory(true);
    setIsCreateModalOpen(true);
  };

  const handleCreateEquipmentModalClick = () => {
    setNewCategory(false);
    setIsCreateModalOpen(true);
  };

  // Fetch equipment from database and display them in a list
  useEffect(() => {
    // Immediately set isInitialLoad to false to ensure Skeleton UI can be removed
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }

    // Condition to check if necessary data is available before fetching equipment
    if (
      employees.length === 0 ||
      equipmentVariants.length === 0 ||
      equipmentTypes.length === 0
    ) {
      setDisplayEmptyState(equipment.length === 0);
      return;
    }

    const fetchEquipment = async () => {
      if (userInfo?.companyId) {
        const equipmentRef = collection(db, 'equipment');
        const q = query(
          equipmentRef,
          where('companyId', '==', userInfo.companyId),
          orderBy('endDate', 'desc')
        );
        const querySnapshot = await getDocs(q);

        const fetchPromises = querySnapshot.docs.map(async (equipmentItem) => {
          const assignedTo =
            employees.find(
              (employee) =>
                employee.id === equipmentItem?.data()?.assignedTo?.id
            ) || null;

          const equipmentType = equipmentTypes.find(
            (type) => type.id === equipmentItem.data().equipmentType.id
          );
          const equipmentVariant = equipmentVariants.find(
            (variant) => variant.id === equipmentItem.data().equipmentVariant.id
          );

          // Prepare equipment data for the state
          return {
            link: `/udstyr/${equipmentItem.id}`,
            id: equipmentItem.id,
            fields: [
              {
                label: 'Variant',
                flex: 30,
                value: (
                  <div
                    className={`equipment-list-row-variant
                  ${
                    equipmentItem?.data()?.inactive
                      ? 'equipment-list-row-variant--inactive'
                      : ''
                  }
                  `}
                  >
                    <div className="equipment-list-row-variant__icon">
                      {equipmentType?.data?.icon}
                    </div>
                    <div className="equipment-list-row-variant-name-wrapper">
                      <span className="equipment-list-row-variant-name">
                        {equipmentVariant?.data?.name}
                      </span>
                      <span className="equipment-list-row-type-name">
                        {equipmentType?.data?.name}
                      </span>
                    </div>
                  </div>
                ),
                sortBy: equipmentVariant?.data?.name,
                searchBy: equipmentVariant?.data?.name,
              },
              {
                label: 'Tildelt til',
                flex: 30,
                value: (
                  <div className="equipment-list-row-profile">
                    {assignedTo ? (
                      <>
                        <Avatar
                          firstName={assignedTo.data.firstName}
                          lastName={assignedTo.data.lastName}
                          profilePhotoUrl={assignedTo.data.profilePhotoUrl}
                        />
                        <div className="equipment-list-row-name-wrapper">
                          <span className="equipment-list-row-name">
                            {assignedTo.data.firstName}{' '}
                            {assignedTo.data.lastName}
                          </span>
                          <span className="equipment-list-row-job">
                            {assignedTo.data.jobPosition}
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className="equipment-list-row-name">-</span>
                    )}
                  </div>
                ),
              },
              {
                label: 'Status',
                flex: 10,
                value: (
                  <div className="equipment-list-row-status">
                    {
                      // If the equipment is inactive, display "Inaktiv"
                      // If the equipment is past the expiration date, display "Udløbet"
                      // If the equipment is within 1 month to expiration date, display "Udløber snart"
                      // If the equipment does not have a serial number, display "Mangler serienummer"
                      // else, display "Aktiv"
                      equipmentItem?.data()?.inactive ? (
                        'Inaktiv'
                      ) : equipmentItem?.data()?.endDate &&
                        new Date(
                          convertToLocalTime(
                            equipmentItem?.data()?.endDate.toDate()
                          )
                        ) < new Date() ? (
                        <div className="equipment-list-row-status equipment-list-row-status--warning">
                          <Icon name="warning" />
                          Udløbet
                        </div>
                      ) : equipmentItem?.data()?.endDate &&
                        new Date(
                          convertToLocalTime(
                            equipmentItem?.data()?.endDate.toDate()
                          )
                        ) <
                          new Date(
                            new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                          ) ? (
                        <div className="equipment-list-row-status equipment-list-row-status--warning">
                          <Icon name="warning" />
                          Udløber inden for 1 måned
                        </div>
                      ) : !equipmentItem?.data()?.serialNumber ? (
                        <div className="equipment-list-row-status equipment-list-row-status--danger">
                          <Icon name="warning" />
                          Mangler serienummer
                        </div>
                      ) : (
                        'Aktiv'
                      )
                    }
                  </div>
                ),
              },
            ],
            filterData: {
              Kategori: equipmentType?.data?.name,
              Variant: equipmentVariant?.data?.name,
              'Tildelt til': assignedTo ? assignedTo.id : '',
              'Vis inaktive': equipmentItem?.data()?.inactive || false,
            },
          };
        });

        const resolvedEquipment = await Promise.all(fetchPromises);
        setEquipment(resolvedEquipment);
        setDisplayEmptyState(resolvedEquipment.length === 0);
      }
    };

    fetchEquipment();
  }, [userInfo, equipmentTypes, equipmentVariants, employees]);

  return (
    <div className="main-layout">
      {!displayEmptyState && (
        <PageTop
          title="Udstyr"
          description="Dit overblik over virksomhedens udstyr og hvem der har hvilket udstyr."
          actions={
            paidSubscription && (
              <Button onClick={handleCreateEquipmentModalClick}>
                <Icon className="light" name="add_circle" />
                Tilføj udstyr
              </Button>
            )
          }
        />
      )}
      {isInitialLoad.current ? (
        <>
          <Skeleton
            height={37}
            style={{
              marginBottom: '32px',
            }}
            count={1}
          />
          <Panels>
            <Panel>
              <Skeleton height={50} count={2} />
            </Panel>
          </Panels>
        </>
      ) : displayEmptyState ? (
        <EmptyState image={EmptyStateImage}>
          {!paidSubscription && (
            <span className="equipment-limited-access">
              Kræver Premium abonnement
            </span>
          )}
          <h3>
            Skab overblik over jeres udstyr og tildel enheder til de enkelte
            medarbejdere
          </h3>
          <p style={{ marginBottom: '32px' }}>
            Med funktionen Udstyr kan du tilknytte den enkelte enhed og info
            herom til den relevante medarbejder. På den måde har du altid styr
            på, hvem der har hvilket udstyr og du ved altid hvor du skal finde
            vigtig info om alle jeres enheder.
          </p>
          {paidSubscription ? (
            <Button onClick={handleCreateEquipmentModalClick}>
              <Icon className="light" name="add_circle" />
              Tilføj første udstyr
            </Button>
          ) : (
            <Button onClick={() => setIsPlansModalOpen(true)}>
              Opgradér for at få Udstyr
            </Button>
          )}
        </EmptyState>
      ) : (
        <div style={!paidSubscription ? { position: 'relative' } : {}}>
          {!paidSubscription && (
            <div className="equipment-limited-cta">
              <Icon name="devices" />
              <h4>Opgradér abonnement for at få adgang til udstyr</h4>
              <p>
                Opgradér abonnement for at få adgang til de oprettede{' '}
                <strong>{equipment.length} stk udstyr</strong>.
              </p>
              <Button onClick={() => setIsPlansModalOpen(true)}>
                Vis prisoversigt
              </Button>
            </div>
          )}
          <div
            style={
              !paidSubscription
                ? {
                    position: 'relative',
                    pointerEvents: 'none',
                    opacity: '0.5',
                  }
                : {}
            }
          >
            <Tabs
              tabs={[
                {
                  label: 'Udstyr',
                  count: equipment.length,
                },
                {
                  label: 'Kategorier',
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <Panels>
              <Panel noPadding>
                {activeTab === 'Udstyr' ? (
                  <List
                    key="equipment"
                    head={[
                      { label: 'Variant', flex: 30, sortable: true },
                      { label: 'Tildelt til', flex: 30, sortable: true },
                      { label: 'Status', flex: 10 },
                    ]}
                    filters={[
                      {
                        label: 'Kategori',
                        options: equipmentTypes.map((type) => ({
                          value: type.data.name,
                          label: type.data.name,
                        })),
                      },
                      {
                        label: 'Variant',
                        options: equipmentVariants.map((variant) => ({
                          value: variant.data.name,
                          label: variant.data.name,
                        })),
                      },
                      {
                        label: 'Tildelt til',
                        options: employees.map((employee) => ({
                          value: employee.id,
                          label: `${employee.data.firstName} ${employee.data.lastName}`,
                        })),
                      },
                      {
                        label: 'Vis inaktive',
                        type: 'checkbox',
                        onChange: (checked) => setShowInactive(checked),
                      },
                    ]}
                    showSearch
                    items={equipment.filter(
                      (item) => showInactive || !item.filterData['Vis inaktive']
                    )}
                  />
                ) : (
                  <List
                    key="equipment-categories"
                    head={[
                      { label: 'Kategori', flex: 30, sortable: true },
                      { label: 'Antal', flex: 15, sortable: true },
                    ]}
                    actions={
                      <Button
                        className="small"
                        buttonStyle="secondary"
                        onClick={handleCreateCategoryModalClick}
                      >
                        Tilføj kategori
                      </Button>
                    }
                    items={equipmentTypes.map((type) => ({
                      link: `/udstyr/kategori/${type.id}`,
                      id: type.id,
                      fields: [
                        {
                          label: 'Kategori',
                          flex: 30,
                          value: (
                            <div className="equipment-list-row-variant">
                              <div className="equipment-list-row-variant__icon">
                                {type?.data?.icon}
                              </div>
                              <div className="equipment-list-row-variant-name-wrapper">
                                <span className="equipment-list-row-variant-name">
                                  {type?.data?.name}
                                </span>
                              </div>
                            </div>
                          ),
                          sortBy: type?.data?.name,
                          searchBy: type?.data?.name,
                        },
                        {
                          label: 'Antal',
                          flex: 15,
                          value: equipment.filter(
                            (equipmentItem) =>
                              equipmentItem.filterData.Kategori ===
                              type.data.name
                          ).length,
                          sortBy: equipment.filter(
                            (equipmentItem) =>
                              equipmentItem.filterData.Kategori ===
                              type.data.name
                          ).length,
                        },
                      ],
                      filterData: {
                        Kategori: type.data.name,
                        Antal: equipment.filter(
                          (equipmentItem) =>
                            equipmentItem.filterData.Kategori === type.data.name
                        ).length,
                      },
                    }))}
                  />
                )}
              </Panel>
            </Panels>
          </div>
        </div>
      )}

      <EquipmentCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        newCategory={newCategory}
      />

      <SubscriptionPlansModal
        isModalOpen={isPlansModalOpen}
        setIsModalOpen={setIsPlansModalOpen}
        companyId={userInfo?.companyId}
        userId={userInfo.id}
      />
    </div>
  );
}
