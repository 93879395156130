import { useState, useContext } from 'react';
import { db } from '../../firebase-config';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { UserContext } from '../../context';
import { useForm } from '../../hooks/useForm';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import './complete-signup-view.css';
import Logo from '../../components/logo/logo';
import TagManager from 'react-gtm-module';

const validateSignupForm = (values) => {
  let errors = {};

  if (!values.firstName.trim()) errors.firstName = 'Fornavn mangler';
  if (!values.lastName.trim()) errors.lastName = 'Efternavn mangler';
  if (!values.jobPosition.trim()) errors.jobPosition = 'Arbejdstitel mangler';
  if (!values.companyName.trim()) errors.companyName = 'Firmanavn mangler';
  if (!values.companyAddress.trim()) errors.companyAddress = 'Adresse mangler';
  if (!values.companyCity.trim()) errors.companyCity = 'By mangler';
  if (!values.companyZipcode || !/^\d+$/.test(values.companyZipcode))
    errors.companyZipcode = 'Postnr. mangler';
  if (!values.companyVat) errors.companyVat = 'CVR-nr. mangler';

  return errors;
};

export default function CompleteSignupView({
  isUserSetupComplete,
  setIsUserSetupComplete,
}) {
  const userInfo = useContext(UserContext);
  const [showVatLookup, setShowVatLookup] = useState(true);
  const [showManualCompanyFields, setShowManualCompanyFields] = useState(false);
  const [companySearchResult, setCompanySearchResult] = useState();
  const [showVatLookupResult, setShowVatLookupResult] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSignupForm, setShowSignupForm] = useState(true);

  const handleCompanyLookup = async (input) => {
    // Reset error message
    setErrorMessage();

    // Delay the search to avoid too many requests until the user is done typing
    setTimeout(() => {
      if (input.length > 2) {
        // Check if the input is a number
        const isNumber = /^\d+$/.test(input);

        // If the input is a number, search by VAT number
        isNumber && companySearch(input);
      }
    }, 500);
  };

  const companySearch = async (input) => {
    // Reset the company search result
    setCompanySearchResult();

    // Use /api/company/search to search for the company
    const response = await fetch('/api/company/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ vat: input }),
    });

    const data = await response.json();

    if (data.company) {
      return setCompanySearchResult(data);
    }

    if (data.status === 'error') {
      setErrorMessage(data.message);
    }
  };

  const handleSetCompanyFromSearch = () => {
    setShowVatLookup(false);
    setShowVatLookupResult(true);

    setValues({
      companyName: companySearchResult.company.name,
      companyAddress: companySearchResult.company.address,
      companyCity: companySearchResult.company.city,
      companyZipcode: companySearchResult.company.zipcode,
      companyVat: companySearchResult.company.vat,
    });
  };

  const handleResetVatLookup = () => {
    setShowVatLookup(true);
    setShowVatLookupResult(false);
    setCompanySearchResult();
    setErrorMessage();

    handleChange({
      companyName: '',
      companyAddress: '',
      companyCity: '',
      companyZipcode: '',
      companyVat: '',
    });
  };

  const handleShowManualCompanyFields = () => {
    setShowManualCompanyFields(!showManualCompanyFields);
    setErrorMessage();
  };

  const handleCompleteSignup = async (formValues) => {
    // Create company information object to be stored in Firestore
    const companyInfoData = {
      name: formValues.companyName,
      address: formValues.companyAddress,
      city: formValues.companyCity,
      zipcode: parseInt(formValues.companyZipcode),
      vat: parseInt(formValues.companyVat),
      owner_user_id: userInfo.uid,
      timeSettings: {
        ownerDateISOString: new Date().toISOString(),
        ownerTimeZoneOffset: new Date().getTimezoneOffset(),
      },
    };

    // Hide the signup form
    setShowSignupForm(false);

    try {
      // Use /api/company/add to add the company
      const response = await fetch('/api/company/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(companyInfoData),
      });

      const data = await response.json();

      if (data.status === 'success' && data.id) {
        // User information object to be stored in Firestore
        const userInfoData = {
          firstName: values.firstName,
          lastName: values.lastName,
          jobPosition: values.jobPosition,
          email: userInfo.email,
          companyId: data.id,
          date_created: Timestamp.now(),
          settings: {
            agreements: {
              exemptFrom1to1: true,
              exemptFromEPR: true,
              exemptFromSalary: true,
            },
            documents: {
              exemptFromEmploymentContract: true,
            },
            mobileApp: {
              access: false,
            },
            timeRegistration: {
              exempt: false,
              individualTimeLogSettings: false,
            },
            role: 'admin',
          },
        };

        const userDocRef = doc(db, 'users', userInfo.uid);

        try {
          // Add user information to Firestore
          await setDoc(userDocRef, userInfoData);

          // Set custom claim for the user
          const userId = userInfo.uid; // Get the user ID of the newly created user
          const response = await fetch('/api/user/setclaim', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: userId,
              companyId: data.id,
              role: 'admin',
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to set custom claim');
          } else {
            // Indicate that signup is complete
            setIsUserSetupComplete(true);

            // Trigger data layer event for user registration
            TagManager.dataLayer({
              dataLayer: {
                event: 'Opret bruger profil',
                label: 'Udfyldt formular og oprettet bruger profil',
              },
            });

            // Window reload to refresh the user token and get context data
            window.location.href = '/';
          }
        } catch (error) {
          console.error('Error adding user info: ', error);
          setShowSignupForm(true);
        }
      } else {
        console.error('Error adding company', data);
        setErrorMessage(data?.message);
        setShowSignupForm(true);
      }
    } catch (error) {
      console.error('Error adding company info: ', error);
      setShowSignupForm(true);
    }
  };

  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      firstName: '',
      lastName: '',
      jobPosition: '',
      companyName: '',
      companyAddress: '',
      companyCity: '',
      companyZipcode: '',
      companyVat: '',
    },
    validateSignupForm,
    handleCompleteSignup
  );

  return (
    <div className="centered-layout complete-signup-view">
      <Logo />
      <div className="centered-layout__content">
        <form
          className="centered-layout__form centered-layout__form"
          onSubmit={handleSubmit}
        >
          <h3>Næsten færdig</h3>
          {showSignupForm && (
            <>
              <p>Udfyld dine informationer for at gøre din profil færdig</p>

              <h4>Dine informationer</h4>
              <InputField
                label="Fornavn"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder="Skriv dit fornavn"
                error={errors.firstName}
              />
              <InputField
                label="Efternavn"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                placeholder="Skriv dit efternavn"
                error={errors.lastName}
              />
              <InputField
                label="Din arbejdstitel"
                name="jobPosition"
                value={values.jobPosition}
                onChange={handleChange}
                placeholder="Hvad er din stilling?"
                error={errors.jobPosition}
              />
              <h4>Din virksomhed</h4>
              <div className="company-search-field">
                {!showManualCompanyFields && showVatLookup && (
                  <>
                    <InputField
                      label="Indtast CVR-nr."
                      type="number"
                      onChange={(event) =>
                        handleCompanyLookup(event.target.value)
                      }
                      placeholder="Indtast CVR-nr."
                      help="Vi finder automatisk din virksomhed ud fra CVR-nr."
                    />
                    {companySearchResult && (
                      <div
                        className="company-search-result"
                        onClick={handleSetCompanyFromSearch}
                      >
                        {companySearchResult.company.name}
                      </div>
                    )}
                    <div className="company-manual-entry">
                      <Button
                        buttonStyle="text-link"
                        className="small"
                        onClick={() => handleShowManualCompanyFields()}
                      >
                        ... eller indtast manuelt
                      </Button>
                    </div>
                  </>
                )}
                {!showManualCompanyFields && showVatLookupResult && (
                  <>
                    <div className="company-selected">
                      <strong>{companySearchResult.company.name}</strong>
                      <br />
                      {companySearchResult.company.address}
                      <br />
                      {companySearchResult.company.zipcode}{' '}
                      {companySearchResult.company.city}
                      <br />
                      CVR-nr: {companySearchResult.company.vat}
                    </div>
                    <div className="company-search-options">
                      <div className="company-manual-entry">
                        <Button
                          buttonStyle="text-link"
                          className="small"
                          onClick={() => handleShowManualCompanyFields()}
                        >
                          ... eller indtast manuelt
                        </Button>
                      </div>
                      <div className="company-search-reset">
                        <Button
                          buttonStyle="text-link"
                          className="small"
                          onClick={() => handleResetVatLookup()}
                        >
                          Ikke korrekt? Søg igen
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {showManualCompanyFields && (
                  <>
                    <InputField
                      label="Firmanavn"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      placeholder="Indtast firmanavn"
                      error={errors.companyName}
                    />

                    <InputField
                      label="Adresse"
                      name="companyAddress"
                      value={values.companyAddress}
                      onChange={handleChange}
                      placeholder="Indtast adresse"
                      error={errors.companyAddress}
                    />
                    <div className="company-address-fields">
                      <InputField
                        className="zipcode"
                        label="Postnr."
                        name="companyZipcode"
                        value={values.companyZipcode}
                        onChange={handleChange}
                        placeholder="Indtast ####"
                        error={errors.companyZipcode}
                      />

                      <InputField
                        className="city"
                        label="By"
                        name="companyCity"
                        value={values.companyCity}
                        onChange={handleChange}
                        placeholder="Indtast by"
                        error={errors.companyCity}
                      />
                    </div>
                    <InputField
                      label="CVR-nr."
                      name="companyVat"
                      value={values.companyVat}
                      onChange={handleChange}
                      placeholder="Indtast CVR-nr."
                      error={errors.companyVat}
                    />
                    <div className="company-manual-entry">
                      <Button
                        buttonStyle="text-link"
                        className="small"
                        onClick={() => handleShowManualCompanyFields()}
                      >
                        Tilbage til automatiske felter
                      </Button>
                    </div>
                  </>
                )}
                {errorMessage && (
                  <div className="company-search-error">{errorMessage}</div>
                )}
              </div>

              <Button type="submit">Færdiggør oprettelse</Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
