import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { UserInfoContext } from '../../context';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import InputField from '../../components/input-field/input-field';
import { useForm } from '../../hooks/useForm';
import Button from '../../components/button/button';
import { Panel } from '../../components/panel/panel';

export default function SubscriptionAddresses() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const [useOtherBillingAddress, setUseOtherBillingAddress] = useState(false);
  const [formFeedback, setFormFeedback] = useState('');
  const [billingEmail, setBillingEmail] = useState('');

  const validateCompanyForm = (values) => {
    let errors = {};

    // Reset form feedback
    setFormFeedback('');

    if (!values.name.trim()) errors.name = 'Firmanavn mangler';
    if (!values.address.trim()) errors.address = 'Adresse mangler';
    if (!values.city.trim()) errors.city = 'By mangler';
    if (!values.zipcode.trim()) errors.zipcode = 'Postnr. mangler';
    if (!values.vat) errors.vat = 'CVR-nr. mangler';

    if (useOtherBillingAddress) {
      if (!values.billing_company_name.trim())
        errors.billing_company_name = 'Firmanavn mangler';
      if (!values.billing_address_line1.trim())
        errors.billing_address_line1 = 'Adresse mangler';
      if (!values.billing_address_city.trim())
        errors.billing_address_city = 'By mangler';
      if (!values.billing_address_postal_code.trim())
        errors.billing_address_postal_code = 'Postnr. mangler';
      if (!values.vat) errors.vat = 'CVR-nr. mangler';
    }

    if (!billingEmail.trim()) errors.billing_email = 'Email mangler';

    // Check if the email is valid
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(billingEmail)
    ) {
      errors.billing_email = 'Ugyldig email';
    }

    return errors;
  };

  // handle switching between billing addresses
  const handleBillingAddressChange = (e) => {
    setUseOtherBillingAddress(e.target.checked);
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      if (userInfo) {
        const companyDocRef = doc(db, 'companies', userInfo.companyId);
        try {
          await setDoc(
            companyDocRef,
            {
              ...values,

              billing_email: billingEmail,
            },
            { merge: true }
          );
          setFormFeedback('Ændringerne er gemt.');
        } catch (error) {
          setFormFeedback('Der skete en fejl. Prøv igen senere.');
          console.error('Error updating company:', error);
        }
      }
    },
    [userInfo, billingEmail]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      billing_company_name: '',
      billing_address_line1: '',
      billing_address_city: '',
      billing_address_postal_code: '',
      billing_vat: '',
      name: '',
      address: '',
      city: '',
      zipcode: '',
      vat: '',
    },
    validateCompanyForm,
    onSubmit
  );

  // Fetch data
  useEffect(() => {
    if (userInfo && userInfo.companyData && isInitialLoad.current) {
      setValues(userInfo.companyData);
      setBillingEmail(
        userInfo.companyData.billing_email || userInfo.email || ''
      );
      isInitialLoad.current = false;
    }
  }, [userInfo, setValues]);

  const handleBillingEmailChange = (e) => {
    setBillingEmail(e.target.value);
  };

  return (
    <Panel header={<h4>Faktureringsoplysninger</h4>}>
      <InputField
        name="useOtherBillingAddress"
        type="checkbox"
        label={`Brug en anden faktureringsadresse`}
        onChange={handleBillingAddressChange}
        value={useOtherBillingAddress}
      />
      <form onSubmit={handleSubmit}>
        {useOtherBillingAddress ? (
          <div key="other-billing-information">
            <InputField
              required
              label="Firmanavn"
              name="billing_company_name"
              value={values.billing_company_name}
              onChange={handleChange}
              placeholder="Indtast firmanavn"
              error={errors.billing_company_name}
            />

            <InputField
              required
              label="Adresse"
              name="billing_address_line1"
              value={values.billing_address_line1}
              onChange={handleChange}
              placeholder="Indtast adresse"
              error={errors.billing_address_line1}
            />
            <div className="company-address-fields">
              <InputField
                required
                className="zipcode"
                label="Postnr."
                name="billing_address_postal_code"
                value={values.billing_address_postal_code}
                onChange={handleChange}
                placeholder="Indtast ####"
                error={errors.billing_address_postal_code}
              />

              <InputField
                required
                className="city"
                label="By"
                name="billing_address_city"
                value={values.billing_address_city}
                onChange={handleChange}
                placeholder="Indtast by"
                error={errors.billing_address_city}
              />
            </div>
            <InputField
              required
              label="CVR-nr."
              name="billing_vat"
              value={values.billing_vat}
              onChange={handleChange}
              placeholder="Indtast CVR-nr."
              error={errors.billing_vat}
            />
          </div>
        ) : (
          <div key="default-information">
            <InputField
              required
              label="Firmanavn"
              name="name"
              value={values.name}
              placeholder="Indtast firmanavn"
              disabled
              readOnly
            />

            <InputField
              required
              label="Adresse"
              name="address"
              value={values.address}
              placeholder="Indtast adresse"
              disabled
              readOnly
            />

            <div className="company-address-fields">
              <InputField
                required
                className="zipcode"
                label="Postnr."
                name="zipcode"
                value={values.zipcode}
                placeholder="Indtast ####"
                disabled
                readOnly
              />

              <InputField
                required
                className="city"
                label="By"
                name="city"
                value={values.city}
                placeholder="Indtast by"
                disabled
                readOnly
              />
            </div>

            <InputField
              required
              label="CVR-nr."
              name="vat"
              value={values.vat}
              disabled
              readOnly
              help="CVR-nr. kan ikke ændres"
            />
          </div>
        )}
        <InputField
          required
          label="Faktureringsemail"
          name="billing_email"
          value={billingEmail}
          onChange={handleBillingEmailChange}
          placeholder="Indtast faktureringsemail"
          error={errors.billing_email}
          help="Vi sender fakturaer til denne email"
        />
        <div className="form-actions">
          <Button type="submit">Gem faktureringsoplysninger</Button>
          {formFeedback && (
            <span className="form-actions__feedback">{formFeedback}</span>
          )}
        </div>
      </form>
    </Panel>
  );
}
