import './timeregistration-user-registrations.css';
import List from '../../list/list';
import Avatar from '../../avatar/avatar';
import TimeRegistrationWorkBar from '../work-bar/timeregistration-work-bar';
import { convertValueToHoursAndMinutes } from '../../../utils/convertValueToHoursAndMinutes';
import { useState, useContext, useMemo } from 'react';
import { UserInfoContext } from '../../../context';
import { convertTimeOffTypeUUIDToName } from '../../../views/timeregistration/timeregistration-utils';
import Icon from '../../icon/icon';
import TimeRegistrationUserRegistrationsModal from './timeregistration-user-registrations-modal';

function TimeRegistrationUserRegistrations({ employees, metrics }) {
  const userInfo = useContext(UserInfoContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEmployeeId, setModalEmployeeId] = useState(null);
  const [modalDate, setModalDate] = useState(null);
  const [items, setItems] = useState([]);

  useMemo(() => {
    const timeOffTypes = userInfo?.companyData?.timeOffTypes || [];
    const processedDates = new Set();

    const handleSetModalOpen = () => {
      setIsModalOpen(!isModalOpen);
    };

    setItems(
      Object.keys(metrics)
        .flatMap((date) => {
          const dateToDisplay = new Date(date).toLocaleDateString('da-DK', {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          return Object.keys(metrics[date])
            .flatMap((employeeId, index) => {
              const employee = employees.find(
                (employee) => employee.id === employeeId
              );

              if (!employee) {
                return null;
              }

              if (employee?.data?.settings?.timeRegistration?.exempt) {
                return null;
              }

              const employeeMetrics = metrics[date][employeeId];

              const allMetricsZero =
                employeeMetrics.actualWorkHours === 0 &&
                employeeMetrics.normHours === 0 &&
                employeeMetrics.overtime.hours === 0 &&
                employeeMetrics.sickLeave.hours === 0 &&
                employeeMetrics.timeOff.hours === 0 &&
                employeeMetrics.holidays.hours === 0;

              if (allMetricsZero) {
                return null;
              }

              const firstInDate = !processedDates.has(date);
              if (firstInDate) {
                processedDates.add(date);
              }

              return {
                id: employee.id,
                className: firstInDate
                  ? 'timeregistration-user-registrations-row-first-in-date'
                  : '',
                onClick: () => {
                  setModalEmployeeId(employee.id);
                  setModalDate(date);
                  handleSetModalOpen();
                },
                fields: [
                  {
                    flex: 15,
                    label: 'Medarbejder',
                    value: (
                      <div
                        className={`timeregistration-user-registrations-row-profile ${
                          firstInDate ? 'first-in-date' : ''
                        }`}
                        {...(firstInDate ? { 'data-date': dateToDisplay } : {})}
                      >
                        <Avatar
                          firstName={employee.data.firstName}
                          lastName={employee.data.lastName}
                          profilePhotoUrl={employee.data.profilePhotoUrl}
                          size="small"
                        />
                        <div className="employees-list-row-name-wrapper">
                          <span className="employees-list-row-name">
                            {employee.data.firstName} {employee.data.lastName}
                          </span>
                        </div>
                      </div>
                    ),
                    sortBy: `${employee.data.firstName} ${employee.data.lastName}`,
                    searchBy: `${employee.data.firstName} ${employee.data.lastName}`,
                  },
                  {
                    label: 'Arbejdstid',
                    flex: 15,
                    value: (
                      <TimeRegistrationWorkBar
                        hours={employeeMetrics?.actualWorkHours || 0}
                        total={employeeMetrics?.normHours || 0}
                      />
                    ),
                    sortBy: employeeMetrics?.actualWorkHours || 0,
                    align: 'right',
                  },
                  {
                    label: 'Sygdom',
                    flex: 8,
                    value: (
                      <div className="timeregistration-user-registrations-row-default">
                        {employeeMetrics?.sickLeave.hasNote ? (
                          <Icon name="comment" />
                        ) : null}
                        {convertValueToHoursAndMinutes(
                          employeeMetrics?.sickLeave.hours || 0
                        )}
                      </div>
                    ),
                    sortBy: employeeMetrics?.sickLeave.hours || 0,
                    align: 'right',
                  },
                  {
                    label: 'Fri',
                    flex: 8,
                    value: (
                      <div className="timeregistration-user-registrations-row-default">
                        {employeeMetrics?.timeOff?.hasNote ? (
                          <Icon name="comment" />
                        ) : null}
                        {convertValueToHoursAndMinutes(
                          employeeMetrics?.timeOff.hours || 0
                        )}
                      </div>
                    ),
                    sortBy: employeeMetrics?.timeOff.hours || 0,
                    align: 'right',
                  },
                  {
                    label: 'Fri type',
                    flex: 10,
                    value: timeOffTypes && timeOffTypes.length > 0 && (
                      <div className="timeregistration-user-registrations-row-timeoff-type">
                        {convertTimeOffTypeUUIDToName(
                          employeeMetrics?.timeOff?.timeOffType,
                          timeOffTypes
                        ) || '-'}
                      </div>
                    ),
                    sortBy: employeeMetrics?.timeOff.hours || 0,
                    align: 'right',
                  },
                  {
                    label: 'Ferie',
                    flex: 8,
                    value: (
                      <div className="timeregistration-user-registrations-row-default">
                        {employeeMetrics?.holidays?.hasNote ? (
                          <Icon name="comment" />
                        ) : null}
                        {convertValueToHoursAndMinutes(
                          employeeMetrics?.holidays.hours || 0
                        )}
                      </div>
                    ),
                    sortBy: employeeMetrics?.holidays.hours || 0,
                    align: 'right',
                  },
                  {
                    label: 'U.f. normtid',
                    flex: 8,
                    value: (
                      <div className="timeregistration-user-registrations-row-default">
                        {employeeMetrics?.overtime?.hasNote ? (
                          <Icon name="comment" />
                        ) : null}
                        {convertValueToHoursAndMinutes(
                          employeeMetrics?.overtime.hours || 0
                        )}
                      </div>
                    ),
                    sortBy: employeeMetrics?.overtime.hours || 0,
                    align: 'right',
                  },
                ],
              };
            })
            .filter((item) => item);
        })
        .filter((item) => item)
    );
  }, [employees, metrics, userInfo?.companyData?.timeOffTypes, isModalOpen]);

  return (
    <div className="timeregistration-user-registrations">
      <List
        hideListActions
        head={[
          { label: 'Medarbejder', sortable: false, flex: 15 },
          { label: 'Arbejdstid', sortable: false, flex: 15, align: 'right' },
          { label: 'Sygdom', sortable: false, flex: 8, align: 'right' },
          { label: 'Fri', sortable: false, flex: 8, align: 'right' },
          { label: 'Fri type', sortable: false, flex: 10, align: 'right' },
          { label: 'Ferie', sortable: false, flex: 8, align: 'right' },
          { label: 'U.f. normtid', sortable: false, flex: 8, align: 'right' },
        ]}
        items={items}
      />
      <TimeRegistrationUserRegistrationsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalEmployeeId={modalEmployeeId}
        modalDate={modalDate}
        metrics={metrics}
      />
    </div>
  );
}

export default TimeRegistrationUserRegistrations;
