import { useRef, useState, useEffect } from 'react';
import { Panel } from '../../components/panel/panel';
import './employee-agreements.css';
import {
  doc,
  collection,
  getDocs,
  where,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import Skeleton from 'react-loading-skeleton';
import ImportantList from '../../components/important-list/important-list';
import AgreementsList from '../../components/agreements/agreements-list/agreements-list';
import { Link } from 'react-router-dom';
import { convertToLocalTime } from '../../utils/formatDates';

export default function EmployeeAgreements({
  employeeData,
  userInfo,
  employeeId,
}) {
  const isInitialLoad = useRef(true);
  const [agreements, setAgreements] = useState([]);
  const [agreement1to1, setAgreement1to1] = useState(null);
  const [agreementEPR, setAgreementEPR] = useState(null);
  const [agreementSalary, setAgreementSalary] = useState(null);
  const [isCreateAgreementModalOpen, setIsCreateAgreementModalOpen] =
    useState(false);
  const [selectedAgreementType, setSelectedAgreementType] = useState(null);

  const handleCreateAgreementClick = (type) => {
    setSelectedAgreementType(type);
    setIsCreateAgreementModalOpen(true);
  };

  // Fetch agreements for the employee
  useEffect(() => {
    // Immediately set isInitialLoad to false to ensure Skeleton UI can be removed
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }

    const fetchAgreements = async () => {
      const agreementsRef = collection(db, 'agreements');
      const q = query(
        agreementsRef,
        where('companyId', '==', userInfo.companyId),
        where('regarding', '==', doc(db, 'users', employeeId)),
        orderBy('date', 'asc')
      );
      const querySnapshot = await getDocs(q);
      const agreementsData = [];

      const fetchPromises = querySnapshot.docs.map(async (agreement) => {
        // Prepare agreement data for the state
        return {
          link: `/aftaler/${agreement.id}`,
          id: agreement.id,
          fields: [
            {
              label: 'Titel',
              flex: 30,
              value: agreement.data().title,
            },
            {
              label: 'Status',
              flex: 10,
              value: agreement.data().isClosed ? 'Afsluttet' : 'Åben',
            },
            {
              label: 'Dato',
              flex: 20,
              value: convertToLocalTime(agreement.data().date.toDate(), {
                dateStyle: 'short',
                timeStyle: 'short',
              }),
            },
          ],
          filterData: {
            Titel: agreement.data().title,
            Type: agreement.data().agreementType,
            Status: agreement.data().isClosed ? 'Afsluttet' : 'Åben',
          },
        };
      });

      const resolvedAgreements = await Promise.all(fetchPromises);
      agreementsData.push(...resolvedAgreements);

      // Set the agreement for 1to1, EPR and Salary based on the fetched agreements by looking up the agreement types
      const agreement1to1 = employeeData?.settings?.agreements?.exemptFrom1to1
        ? null
        : agreementsData.find(
            (agreement) =>
              agreement.filterData.Type === '1 til 1' &&
              agreement.filterData.Status === 'Åben'
          );

      const agreementEPR = employeeData?.settings?.agreements?.exemptFromEPR
        ? null
        : agreementsData.find(
            (agreement) =>
              agreement.filterData.Type === 'Udviklingssamtale' &&
              agreement.filterData.Status === 'Åben'
          );

      const agreementSalary = employeeData?.settings?.agreements
        ?.exemptFromSalary
        ? null
        : agreementsData.find(
            (agreement) =>
              agreement.filterData.Type === 'Lønsamtale' &&
              agreement.filterData.Status === 'Åben'
          );

      setAgreement1to1(agreement1to1 ? agreement1to1.id : null);
      setAgreementEPR(agreementEPR ? agreementEPR.id : null);
      setAgreementSalary(agreementSalary ? agreementSalary.id : null);
      setAgreements(agreementsData);
    };

    fetchAgreements();
  }, [employeeId, userInfo.companyId, employeeData?.settings]);

  return (
    <>
      {isInitialLoad.current ? (
        <Panel>
          <Skeleton height={164} count={1} />
          <Skeleton height={50} count={2} />
        </Panel>
      ) : (
        <>
          <ImportantList
            header={<h4>Vigtige aftaler</h4>}
            content={[
              employeeData?.settings?.agreements?.exemptFrom1to1
                ? null
                : {
                    title: '1 til 1',
                    text: agreement1to1
                      ? `Afholdes d. ${
                          agreements.find(
                            (agreement) => agreement.id === agreement1to1
                          ).fields[2].value
                        }`
                      : 'Ikke planlagt',
                    missing: agreement1to1 ? false : true,
                    actions: agreement1to1 ? (
                      <Link to={`/aftaler/${agreement1to1}`}>
                        <span>Gå til aftale</span>
                      </Link>
                    ) : (
                      <div
                        onClick={() => handleCreateAgreementClick('1 til 1')}
                      >
                        Opret
                      </div>
                    ),
                  },
              employeeData?.settings?.agreements?.exemptFromEPR
                ? null
                : {
                    title: 'Udviklingssamtale',
                    text: agreementEPR
                      ? `Afholdes d. ${
                          agreements.find(
                            (agreement) => agreement.id === agreementEPR
                          ).fields[2].value
                        }`
                      : 'Ikke planlagt',
                    missing: agreementEPR ? false : true,
                    actions: agreementEPR ? (
                      <Link to={`/aftaler/${agreementEPR}`}>
                        <span>Gå til aftale</span>
                      </Link>
                    ) : (
                      <div
                        onClick={() =>
                          handleCreateAgreementClick('Udviklingssamtale')
                        }
                      >
                        Opret
                      </div>
                    ),
                  },
              employeeData?.settings?.agreements?.exemptFromSalary
                ? null
                : {
                    title: 'Lønsamtale',
                    text: agreementSalary
                      ? `Afholdes d. ${
                          agreements.find(
                            (agreement) => agreement.id === agreementSalary
                          ).fields[2].value
                        }`
                      : 'Ikke planlagt',
                    missing: agreementSalary ? false : true,
                    actions: agreementSalary ? (
                      <Link to={`/aftaler/${agreementSalary}`}>
                        <span>Gå til aftale</span>
                      </Link>
                    ) : (
                      <div
                        onClick={() => handleCreateAgreementClick('Lønsamtale')}
                      >
                        Opret
                      </div>
                    ),
                  },
            ]}
          />
          <AgreementsList
            showNewAgreementButton={true}
            agreements={agreements}
            showRegardingFilter={false}
            isCreateModalOpen={isCreateAgreementModalOpen}
            setIsCreateModalOpen={setIsCreateAgreementModalOpen}
            selectedAgreementType={selectedAgreementType}
            selectedRegardingId={employeeId}
          />
        </>
      )}
    </>
  );
}
