import { useContext, useRef, useState, useEffect } from 'react';
import { Panel } from '../../components/panel/panel';
import List from '../../components/list/list';
import { EquipmentContext } from '../../context';
import './employee-equipment.css';
import {
  doc,
  collection,
  getDocs,
  where,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../components/icon/icon';
import EquipmentCreateModal from '../equipment/equipment-create-modal';
import Button from '../../components/button/button';
import { convertToLocalTime } from '../../utils/formatDates';

export default function EmployeeEquipment({ userInfo, employeeId }) {
  const isInitialLoad = useRef(true);
  const [equipment, setEquipment] = useState([]);
  const { equipmentTypes, equipmentVariants } = useContext(EquipmentContext);
  const [showInactive, setShowInactive] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  // Fetch equipment for the employee
  useEffect(() => {
    // Immediately set isInitialLoad to false to ensure Skeleton UI can be removed
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }

    const fetchEquipment = async () => {
      const equipmentRef = collection(db, 'equipment');
      const q = query(
        equipmentRef,
        where('companyId', '==', userInfo.companyId),
        where('assignedTo', '==', doc(db, 'users', employeeId)),
        orderBy('endDate', 'desc')
      );
      const querySnapshot = await getDocs(q);

      const fetchPromises = querySnapshot.docs.map(async (equipmentItem) => {
        const equipmentType = equipmentTypes.find(
          (type) => type.id === equipmentItem.data().equipmentType.id
        );
        const equipmentVariant = equipmentVariants.find(
          (variant) => variant.id === equipmentItem.data().equipmentVariant.id
        );

        // Prepare equipment data for the state
        return {
          link: `/udstyr/${equipmentItem.id}`,
          id: equipmentItem.id,
          fields: [
            {
              label: 'Variant',
              flex: 30,
              value: (
                <div
                  className={`equipment-list-row-variant
                ${
                  equipmentItem?.data()?.inactive
                    ? 'equipment-list-row-variant--inactive'
                    : ''
                }
                `}
                >
                  <div className="equipment-list-row-variant__icon">
                    {equipmentType?.data?.icon}
                  </div>
                  <div className="equipment-list-row-variant-name-wrapper">
                    <span className="equipment-list-row-variant-name">
                      {equipmentVariant?.data?.name}
                    </span>
                    <span className="equipment-list-row-type-name">
                      {equipmentType?.data?.name}
                    </span>
                  </div>
                </div>
              ),
              sortBy: equipmentVariant?.data?.name,
              searchBy: equipmentVariant?.data?.name,
            },
            {
              label: 'Status',
              flex: 10,
              value: (
                <div className="equipment-list-row-status">
                  {
                    // If the equipment is inactive, display "Inaktiv"
                    // If the equipment is past the expiration date, display "Udløbet"
                    // If the equipment is within 1 month to expiration date, display "Udløber snart"
                    // If the equipment does not have a serial number, display "Mangler serienummer"
                    // else, display "Aktiv"
                    equipmentItem?.data()?.inactive ? (
                      'Inaktiv'
                    ) : equipmentItem?.data()?.endDate &&
                      new Date(
                        convertToLocalTime(
                          equipmentItem?.data()?.endDate.toDate()
                        )
                      ) < new Date() ? (
                      <div className="equipment-list-row-status equipment-list-row-status--warning">
                        <Icon name="warning" />
                        Udløbet
                      </div>
                    ) : equipmentItem?.data()?.endDate &&
                      new Date(
                        convertToLocalTime(
                          equipmentItem?.data()?.endDate.toDate()
                        )
                      ) <
                        new Date(
                          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                        ) ? (
                      <div className="equipment-list-row-status equipment-list-row-status--warning">
                        <Icon name="warning" />
                        Udløber inden for 1 måned
                      </div>
                    ) : !equipmentItem?.data()?.serialNumber ? (
                      <div className="equipment-list-row-status equipment-list-row-status--danger">
                        <Icon name="warning" />
                        Mangler serienummer
                      </div>
                    ) : (
                      'Aktiv'
                    )
                  }
                </div>
              ),
            },
          ],
          filterData: {
            Kategori: equipmentType?.data?.name,
            Variant: equipmentVariant?.data?.name,
            'Vis inaktive': equipmentItem?.data()?.inactive || false,
          },
        };
      });

      const resolvedEquipment = await Promise.all(fetchPromises);
      setEquipment(resolvedEquipment);
    };

    fetchEquipment();
  }, [employeeId, userInfo.companyId, equipmentTypes, equipmentVariants]);

  return (
    <>
      {isInitialLoad.current ? (
        <Panel>
          <Skeleton height={164} count={1} />
          <Skeleton height={50} count={2} />
        </Panel>
      ) : (
        <Panel noPadding>
          <List
            key="equipment"
            head={[
              { label: 'Variant', flex: 20, sortable: true },
              { flex: 10, label: 'Status' },
            ]}
            actions={
              <Button
                className="small"
                buttonStyle="secondary"
                onClick={() => setIsCreateModalOpen(true)}
              >
                Tilføj udstyr
              </Button>
            }
            filters={[
              {
                label: 'Kategori',
                options: equipmentTypes.map((type) => ({
                  value: type.data.name,
                  label: type.data.name,
                })),
              },
              {
                label: 'Variant',
                options: equipmentVariants.map((variant) => ({
                  value: variant.data.name,
                  label: variant.data.name,
                })),
              },
              {
                label: 'Vis inaktive',
                type: 'checkbox',
                onChange: (checked) => setShowInactive(checked),
              },
            ]}
            showSearch
            items={equipment.filter(
              (item) => showInactive || !item.filterData['Vis inaktive']
            )}
          />
        </Panel>
      )}

      <EquipmentCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        assignedTo={employeeId}
      />
    </>
  );
}
